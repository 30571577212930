<template>
  <section>
    <content-header :title="$t('notificationsMaintenance')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterNotifications')" />
        </div>
        <FilterManager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="getNotifications(1)"
        />
      </div>

      <separator class="my-4" :text="$t('listOfNotifications')" />
      <div class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :on-click="goToCreate"
            :text="$t('create')"
          />
        </div>
      </div>

      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />

      <div class="row">
        <div v-for="(notification, index) in notifications.data" :key="index" class="mt-3 col-12">
          <SearchResult
            :value="notification"
            @on-edit="onEditOrLink(notification, 0)"
            @on-link="onEditOrLink(notification, 1)"
            @on-clone="onClone(notification)"
            @on-toggle-enability="onToggleActiveState(notification)"
            @on-delete="openDeleteModal(notification)"
          />
        </div>
      </div>
      <CustomPagination
        v-if="notifications.count > 0"
        id="pgPager"
        class="my-4"
        :data="notifications"
        :page-size="pageSize"
        @pagination-go-page="getNotifications($event)"
        @pagination-rows-per-page="getNotifications(pageIndex, $event)"
      />
    </div>

    <CustomModal
      v-model="showNotificationFormModal"
      :title="!editModel ? $t('createNotification') : $t('editNotification')"
    >
      <NotificationForm
        v-if="showNotificationFormModal"
        v-model="editModel"
        @close="showNotificationFormModal = false"
        @submitted="getNotifications(1)"
      />
    </CustomModal>

    <DeleteModal
      v-model="showDeleteModal"
      :display-label="false"
      error-message="notificationNameIsNotCorrect"
      :item="editModel"
      name="notificationName"
      @on-confirm="onDelete()"
    />
  </section>
</template>

<script>
import CustomPagination from '@/components/CustomPagination';
import SubHeader from '@/components/SubHeader';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import CustomModal from '@/components/basics/modal/CustomModal';
import SearchResult from './components/SearchResult';
import FilterManager from '@/components/FilterManager';
import contentHeader from '@/components/Header';
import DeleteModal from '@/components/DeleteModal';
import Separator from '@/components/Separator';
import sessionState from '@/mixins/sessionState';
import NotificationForm from './components/NotificationForm';
import {
  getNotifications as _getNotifications,
  deleteNotification as _deleteNotification,
  toggleActiveState as _toggleActiveState
} from '@/services/NotificationService';

export default {
  name: 'Notifications',
  mixins: [sessionState],
  components: {
    CustomPagination,
    CustomNotFound,
    SearchResult,
    SubHeader,
    FilterManager,
    contentHeader,
    Separator,
    NotificationForm,
    CustomModal,
    DeleteModal,
  },
  data() {
    return {
      selectedFilters: {
        searchCriteria: null
      },
      notifications: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      pageIndex: 1,
      noResultsFound: false,
      showDeleteModal: false,
      showNotificationFormModal: false,
      editModel: null,
    };
  },
  mounted() {
    // load current state of this view
    const previousStateData = this.loadAndRemoveSessionData('notificationData');
    if (previousStateData != null) {
      Object.assign(this.$data, previousStateData);
    }

    this.getNotifications();
  },
  computed: {
    filtersConfig() {
      return [
        {
          rules: '',
          name: 'searchCriteria',
          label: this.$t('searchCriteria'),
          component: 'BaseInput',
          placeholder: `${this.$t('name')}, ${this.$t('description')}, ${this.$t('subjectSpanish')}, ${this.$t('subjectEnglish')}`,
          fieldtext: 'searchCriteria',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4 col-lg-4',
          initialValue: this.selectedFilters.searchCriteria,
          value: this.selectedFilters.searchCriteria,
        },
      ];
    },
  },
  methods: {
    openDeleteModal(data) {
      this.showDeleteModal = true;
      this.editModel = { ...data };
    },
    onDelete() {
      _deleteNotification(this.editModel.notificationId)
        .then(async () => {
          this.ShowSuccessToast(this.$t('successfullyDeleted'));
          this.showDeleteModal = false;
          await this.getNotifications();
          this.editModel = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onToggleActiveState(notification) {
      _toggleActiveState(notification.notificationId)
        .then(async () => {
          this.ShowSuccessToast();
          await this.getNotifications();
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onClone(notification){
      // Persist current state of this view
      this.saveSessionData('notificationData', Object.assign({}, this.$data));

      this.$router.push({
        name: 'NotificationClone',
        params: { id: notification.notificationId },
      });
    },
    async goToCreate() {
      // Persist current state of this view
      this.saveSessionData('notificationData', Object.assign({}, this.$data));

      this.$router.push({ name: 'NotificationCreate' });
    },
    async onEditOrLink(notification, step) {
      // Persist current state of this view
      this.saveSessionData('notificationData', Object.assign({}, this.$data));

      this.$router.push({
        name: 'NotificationEdit',
        params: { id: notification.notificationId, step },
      });
    },
    async getNotifications(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
        searchCriteria: this.selectedFilters.searchCriteria || null,
      };

      await _getNotifications(payload)
        .then(({ data }) => {
          this.notifications = data;
          this.noResultsFound = !this.notifications.count;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
