<template>
  <b-container class="mt-3" fluid>
    <step-wizard
      class="mb-3"
      :step="stepIndex"
      :tabs="tabs"
      @OnStepChange="onStepChange($event)"
    />
    <div id="tab-container">
      <div
        v-show="isActive(0)"
        class="tab-pane"
        role="tabpanel"
      >
        <notification-form class="mb-3" :value="model" @go-to="onStepChange($event)" />
      </div>
      <div
        v-show="isActive(1)"
        class="tab-pane"
        role="tabpanel"
      >
        <link-office v-if="isEditMode" :value="$route.params.id" />
      </div>
    </div>
  </b-container>
</template>

<script>
import StepWizard from '@/components/StepWizard';
import NotificationForm from './components/NotificationForm';
import LinkOffice from './components/LinkOffice';
import { getNotification as _getNotification } from '@/services/NotificationService';

export default {
  name: 'NotificacionMaster',
  components: {
    StepWizard,
    NotificationForm,
    LinkOffice,
  },
  props:{
    step: {
      type: Number,
      require: false,
    }
  },
  data() {
    return {
      stepIndex: 0,
      model: {
        notificationId: 0,
        notificationName: null,
        notificationDescription: null,
        notificationSubject: null,
        notificationText: null,
        notificationShortText: null,
        notificationSubjectEN: null,
        notificationTextEN: null,
        notificationShortTextEN: null,
        defaultNotification: false,
        enabled: false,
      },
    };
  },
  watch: {
    $route: {
      immediate: true,
      async handler(val) {
        if (['NotificationEdit', 'NotificationClone'].includes(val.name)) {
          const notificationId = Number(val.params.id);
          await this.loadNotification(notificationId);
          if (val.name == 'NotificationClone') {
            this.model.notificationId = 0;
            this.model.defaultNotification = false;
          }
        }
        if (this.step)
          this.onStepChange(this.step);
      },
    },
  },
  computed: {
    isEditMode() {
      return this.$route.name === 'NotificationEdit';
    },
    tabs() {
      if (!this.isEditMode) {
        return [
          {
          name: this.$t('notificationMaintenance'),
          icon: 'fas fa-plus',
          disabled: false,
        },
        ]
      }
      return [
        {
          name: this.$t('notificationMaintenance'),
          icon: 'fas fa-plus',
          disabled: false,
        },
        {
          name: this.$t('linkOffice'),
          icon: 'far fa-list',
          disabled: false,
        },
      ];
    },
  },
  methods: {
    isActive(step) {
      return this.stepIndex == step;
    },
    onStepChange(step) {
      this.stepIndex = step;
    },
    loadNotification(id) {
      return _getNotification(id)
          .then(({data}) => {
            this.model = {
              ...this.model,
              ...data
            };
          });
    },
  },
};
</script>

<style lang="scss" scoped></style>
