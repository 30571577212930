<template>
  <section>
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterOffices')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="getLocations"
        />
      </div>

      <separator class="my-4" :text="$t('listOfOffices')" />

      <custom-not-found v-if="!locations.length" :text="$t('noResultsFound')" />

      <div v-if="locations.length" class="main-page-container">
        <b-row>
          <div class="col-12">
            <sub-header class="sub-header-check" :title="$t('selectall')" >
              <template v-slot:before>
                <b-form-checkbox
                  :checked="isAllChecked"
                  :disabled="loadings['bulk']"
                  @change="onBulkToggleLink($event)"
                  size="lg"
                  class="d-inline"
                  switch
                />
              </template>
            </sub-header>
          </div>
        </b-row>
        <b-row class="text-center">
            <div
              v-for="(location, index) in locations"
              :key="index"
              class="col-3 mb-4"
            >
              <p class="m-0">
                {{ location.locationName }}
              </p>
              <b-form-checkbox
                :checked="location.selected"
                :disabled="loadings[location.locationConfigurationId] || loadings['bulk']"
                @change="onToggleLink($event, location)"
                size="lg"
                switch
              />
            </div>
        </b-row>
        <div class="row">
          <div class="col-sm-12 col-md-12 mt-5">
            <base-filled-button
              bg-color="#707070"
              icon-class="fas fa-ban"
              class="mx-0"
              :on-click="() => $router.push({ name: 'Notifications' })"
              :text="$t('cancel')"
            />

            <button
              class="btn btn-outline-primary float-right"
              variant="primary"
              @click="() => $router.push({ name: 'Notifications' })"
            >
              <i class="far fa-arrow-alt-circle-right" /> {{ $t('finalize') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import FilterManager from '@/components/FilterManager';
import SubHeader from '@/components/SubHeader';
import Separator from '@/components/Separator';
import {
  getLocations as _getLocations,
  toggleLink as _toggleLink,
  bulkToggleLink as _bulkToggleLink,
} from '@/services/NotificationService';
import {
  getCompanies as _getCompanies,
} from '@/services/CompanyService';

export default {
  name: 'BindCustomer',
  components: {
    CustomNotFound,
    FilterManager,
    SubHeader,
    Separator,
  },
  props: {
    value: {
      type: [Number, String],
      require: false,
    },
  },
  data() {
    return {
      loadings: {},
      locations: [],
      selectedFilters: {
        company: [],
        name: null,
      },
      lastSelectedFilters: {
        company: [],
        name: null,
      },
      filters: {
        companies: [],
      },
    };
  },
  mounted() {
    this.getCompanies();
    this.getLocations();
  },
  computed: {
    isAllChecked() {
      return !this.locations.some(l => !l.selected);
    },
    filtersConfig() {
      return [
        {
          rules: '',
          name: 'company',
          label: this.$t('company'),
          component: 'FilterMultiSelect',
          options: this.filters.companies,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'text',
          fieldvalue: 'value',
          clearable: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.company,
          onChanged: this.onCompanyChanged,
        },
        {
          rules: '',
          name: 'name',
          label: this.$t('officeName'),
          component: 'BaseInput',
          placeholder: this.$t('officeName'),
          class: 'col-sm-12 col-md-4',
          initialValue: this.selectedFilters.name,
          value: this.selectedFilters.name,
        },
      ];
    },
  },

  methods: {
    onCompanyChanged(value) {
      this.selectedFilters.company = [];
      if (value) {
        this.selectedFilters.company = [value];
      }
    },
    onToggleLink(selected, location){
      this.$set(this.loadings, location.locationConfigurationId, true);
      _toggleLink(this.value, location.locationConfigurationId)
        .then(() => {
          location.selected = selected;
          this.ShowSuccessToast();
          this.$set(this.loadings, location.locationConfigurationId, false);
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onBulkToggleLink(selected){
      this.$set(this.loadings, 'bulk', true);
      _bulkToggleLink(this.value, {...this.lastSelectedFilters, selected })
        .then(async () => {
          this.ShowSuccessToast();
          await this.getLocations(this.lastSelectedFilters);
          this.$set(this.loadings, 'bulk', false);
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getCompanies() {
      await _getCompanies()
        .then((response) => {
          this.filters.companies = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getLocations(payload) {
      payload = payload || {
        name: this.selectedFilters.name || null,
        companyId: this.selectedFilters.company.length ?
          this.selectedFilters.company[0].value : null,
      }
      this.lastSelectedFilters = { ...payload };
      await _getLocations(this.value, payload)
        .then(({ data }) => {
          this.locations = data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .sub-header-check > div {
    display: flex;
    .custom-switch{
      margin-top: -4px;
    }
}
</style>
