<template>
  <div class="StepWizard">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      class="StepWizard-Step"
      :class="{
        active: cascadeActivation ? step >= index : step == index,
        disabled: tab.disabled,
      }"
    >
      <div class="StepWizard-Step__Circle" @click="OnStepChange(tab, index)">
        <i class="icon" :class="tab.icon || defaultIcon" />
        <p class="StepWizard-Step__Circle--Text text-truncate">
          {{ tab.name }}
        </p>
      </div>
      <div class="StepWizard-Step__Separator" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'StepWizard',
  props: {
    step: {
      type: Number,
      default: () => 0,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    defaultIcon: {
      type: String,
      default: () => 'fa-layer-plus',
    },
    cascadeActivation: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    OnStepChange(tab, step) {
      if (tab.disabled) return;
      this.$emit('OnStepChange', step);
    },
  },
};
</script>
<style lang="scss" scoped>
.StepWizard {
  min-height: 100px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 100%;
  @include respond(phone) {
    margin-bottom: 50px;
  }
  &-Step {
    display: flex;
    align-items: center;
    margin: 25px 0;
    @include respond(phone) {
      margin: 15px 0;
    }
    &__Circle {
      height: 65px;
      width: 65px;
      background-color: #bcbcbc;
      border-radius: 100%;
      border: 8px solid $color-label-default;
      transition: all 0.2s;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      @include respond(phone) {
        height: 40px;
        width: 40px;
        border: 5px solid $color-label-default;
      }
      &--Text {
        position: absolute;
        bottom: 0;
        margin: 0;
        transform: translateY(180%);
        white-space: nowrap;
        font-size: 12px;
        max-width: 130px;
        @include respond(phone) {
          font-size: 10px;
        }
      }
      .icon {
        font-size: 30px;
        color: $color-white;
        transition: all 0.2s;
        @include respond(phone) {
          font-size: 15px;
        }
      }
    }
    &__Separator {
      height: 6px;
      width: 88px;
      background-color: $color-label-default;
      @include respond(phone) {
        width: 60px;
      }
    }
    &:hover {
      .StepWizard-Step__Circle {
        height: 75px;
        width: 75px;
        @include respond(phone) {
          height: 40px;
          width: 40px;
        }
        .icon {
          font-size: 35px;
          @include respond(phone) {
            font-size: 15px;
          }
        }
      }
    }
    &:last-child {
      .StepWizard-Step__Separator {
        display: none;
      }
    }
  }
}
.active {
  transition: all 0.2s;
  .StepWizard-Step__Circle {
    @include respond(phone) {
      height: 40px;
      width: 40px;
    }
    background-color: $color-primary;
    height: 75px;
    width: 75px;
    &--Text {
      transform: translateY(160%);
      @include respond(phone) {
        transform: translateY(180%);
      }
    }
    .icon {
      font-size: 35px;
      @include respond(phone) {
        font-size: 15px;
      }
    }
  }
}
.disabled {
  transition: none 0.2s;
  .StepWizard-Step__Circle {
    background-color: $color-label-default;
    height: 65px;
    width: 65px;
    cursor: not-allowed;
    @include respond(phone) {
      height: 40px;
      width: 40px;
    }
    &--Text {
      transform: translateY(160%);
      @include respond(phone) {
        transform: translateY(180%);
      }
    }
    .icon {
      font-size: 35px;
      @include respond(phone) {
        font-size: 15px;
      }
    }
  }
  &:hover {
    .StepWizard-Step__Circle {
      height: 65px;
      width: 65px;
      @include respond(phone) {
        height: 40px;
        width: 40px;
      }
    }
  }
}
/* @include respond(phone){
.StepWizard {
    margin-bottom: 50px;
  &-Step {
    margin: 15px 0;
    &__Circle {
      height: 40px;
      width: 40px;
      border: 5px solid $color-label-default;
      &--Text{
        font-size: 10px;
      }
      .icon {
        font-size: 15px;
      }
    }
    &__Separator {
      width: 40px;
    }
  }
}
.active {
  .StepWizard-Step__Circle {
    height: 40px;
    width: 40px;
    &--Text{
    transform: translateY(180%);
    }
    .icon {
      font-size: 15px;
    }
  }
}
.disabled {
  .StepWizard-Step__Circle {
    height: 40px;
    width: 40px;
    &--Text{
    transform: translateY(180%);
    }
    .icon {
      font-size: 15px;
    }
  }
    &:hover{
    .StepWizard-Step__Circle {
      height: 40px;
      width: 40px;
    }
    }
}
} */
</style>