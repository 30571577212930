<template>
  <div 
    class="p-4 listview card border-light"  
    :class="{ 'lightgreen': value.enabled, 'grey': !value.enabled }">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-3">
        <div class="cell">
          <label>{{ $t('ID') }}: </label>
          <span>{{ value.notificationId || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('name') }}: </label>
          <span class="text-capitalize">{{ value.notificationName || '-' }}</span>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div class="cell">
          <label>{{ $t('subjectSpanish') }}: </label>
          <span>{{ value.notificationSubject || '-' }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('subjectEnglish') }}: </label>
          <span>{{ value.notificationSubjectEN || '-' }}</span>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div class="cell">
          <label>{{ $t('default') }}: </label>
          <span>{{ $t(value.defaultNotification ? 'yes' : 'no') }}</span>
        </div>
        <div class="cell">
          <label>{{ $t('active') }}: </label>
          <span>{{ $t(value.enabled ? 'yes' : 'no') }}</span>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-md-3">
        <action-button
          :actions="actions"
          :text="$t('actions')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ActionButton from '@/components/basics/buttons/ActionButton';

export default {
  name: 'NotificationSearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    actions() {
      return [
        {
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#105AEE',
          action: () => {
            this.$emit('on-edit');
          }
        },
        {
          label: this.$t('link'),
          iconClass: 'far fa-list',
          iconColor: '#105AEE',
          action: () => {
            this.$emit('on-link');
          },
        },
        {
          label: this.$t('clone'),
          iconClass: 'far fa-clone',
          iconColor: '#198E0F',
          action: () => this.$emit('on-clone'),
        },
        {
          label: this.$t(this.value.enabled ? 'deactivate' : 'activate'),
          iconClass: this.value.enabled ? 'far fa-user-unlock' : 'far fa-user-lock',
          iconColor: this.value.enabled ? '#FF0000' : '#FF8105',
          action: () => {
            this.$emit('on-toggle-enability');
          },
        },
        {
          label: this.$t('delete'),
          iconClass: 'far fa-trash-alt',
          iconColor: '#FF0000',
          visible: !this.value.defaultNotification,
          action: () => this.$emit('on-delete'),
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.listview .cell {
  clear: left;
}
.listview .cell label {
  float: left;
  font-weight: bold;
}
.listview .cell span,
::v-deep .formatted-text label {
  margin-left: 5px;
  color: $color-primary;
  font-weight: bold;
}
.card {
  word-break: break-word;
  &.grey {
    border: 1px solid #d0cccc !important;
    background-color: #f8f8f8;
    ::v-deep .c-actionButton {
      border: 1px solid #d0cccc !important;
    }
  }

  &.lightgreen {
    border: 1px solid #a4afa5 !important;
    background-color: #f2fff2;
    ::v-deep .c-actionButton {
      border: 1px solid #a4afa5 !important;
    }
  }
}
</style>
