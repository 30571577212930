<template>
  <section>
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t(value.notificationId ? 'editNotification' : 'createNotification')" />
        </div>
        <validation-observer ref="form" novalidate>
          <div class="row">
            <div class="col-md-12">
              <div class="card" style="border: white">
                <div class="row">
                  <div class="col-md-12" />
                  <div class="col-md-12">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|max:100"
                    >
                      <base-input
                        v-model="value.notificationName"
                        :error="errors[0]"
                        :error-msg="$t('invalidField')"
                        :label="$t('name')"
                      />
                    </validation-provider>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="d-flex justify-content-between">
                        <label>{{ $t(showEnglishSubject ? 'subjectEnglish' : 'subjectSpanish') }}:</label>
                        <b-form-checkbox
                          v-model="showEnglishSubject"
                          switch
                        >
                          {{ $t('showEnglish') }}
                        </b-form-checkbox>
                      </div>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|max:4000"
                      >
                        <base-input
                          v-model="currentSubject"
                          :error="errors[0]"
                          :error-msg="$t('invalidField')"
                        />
                      </validation-provider>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="d-flex justify-content-between">
                        <label>{{ $t(showEnglishText ? 'textEnglish' : 'textSpanish') }}:</label>
                        <b-form-checkbox
                          v-model="showEnglishText"
                          class="mb-1 float-right"
                          switch
                        >
                          {{ $t('showEnglish') }}
                        </b-form-checkbox>
                      </div>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <div :style="{ display: isSMS ? 'none' : 'block'}">
                          <editor
                            v-model="currentText"
                            :init="editorInit"
                          />
                        </div>
                        <div :style="{ display: isSMS ? 'block' : 'none'}">
                          <textarea
                            v-model="currentText"
                            class="smsLongText"
                            rows="5"
                          />
                        </div>
                        <error
                          v-if="errors[0]"
                          class="mt-n1"
                          :message="$t('invalidField')"
                          variant="no-border no-background"
                        />
                      </validation-provider>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="d-flex justify-content-between">
                        <label>{{ $t(showEnglishShortText ? 'shortTextEnglish' : 'shortTextSpanish') }}:</label>
                        <b-form-checkbox
                          v-model="showEnglishShortText"
                          class="mb-1 float-right"
                          switch
                        >
                          {{ $t('showEnglish') }}
                        </b-form-checkbox>
                      </div>
                      <div :style="{ display: isSMS ? 'none' : 'block'}">
                        <editor
                          v-model="currentShortText"
                          :init="{...editorInit, height: 150 }"
                        />
                      </div>
                      <div :style="{ display: isSMS ? 'block' : 'none'}">
                        <textarea
                          v-model="currentShortText"
                          class="smsShortText"
                          rows="5"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="max:2000"
                    >
                      <base-input
                        v-model="value.notificationDescription"
                        :textarea="true"
                        :error="errors[0]"
                        :error-msg="$t('invalidField')"
                        :label="$t('description')"
                      />
                    </validation-provider>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-sm-12 col-md-12 d-flex">
                    <b-form-checkbox
                      v-model="value.enabled"
                      class="mt-2 chk-available mr-3"
                      switch
                    >
                      {{ $t('active') }}
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="value.defaultNotification"
                      class="mt-2 chk-available"
                      switch
                    >
                      {{ $t('default') }}
                    </b-form-checkbox>
                  </div>
                  <div class="col-sm-12 col-md-12 mt-5">
                    <base-filled-button
                      bg-color="#707070"
                      icon-class="fas fa-ban"
                      class="mx-0"
                      :on-click="() => $router.push({ name: 'Notifications' })"
                      :text="$t('cancel')"
                    />

                    <button
                      class="btn btn-primary float-right mx-2"
                      @click="onSubmit(true)"
                    >
                      {{ $t( isCreateMode ? 'save' : (isCloneMode ? 'clone' : 'saveContinue')) }} <i class="far fa-arrow-alt-circle-right" />
                    </button>


                    <button
                      v-if="isEditMode"
                      class="btn btn-outline-primary float-right"
                      variant="primary"
                      @click="onSubmit()"
                    >
                      <i class="far fa-save" /> {{ $t('save') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </validation-observer>
      </div>
    </div>
  </section>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import BaseInput from '@/components/BaseInput';
import SubHeader from '@/components/SubHeader';
import Error from '@/components/basics/error/CustomError.vue';
import sessionState from '@/mixins/sessionState';
import {
  submitNotification as _submitNotification,
} from '@/services/NotificationService';

export default {
  name: 'NotificationsForm',
  mixins: [sessionState],
  components: {
    SubHeader,
    Editor,
    BaseInput,
    Error,
  },
  props: {
    value: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      showEnglishSubject: false,
      showEnglishText: false,
      showEnglishShortText: false,
    };
  },
  computed: {
    isSMS() {
      return this.value.notificationName?.toUpperCase().endsWith('SMS');
    },
    isCreateMode() {
      return this.$route.name === 'NotificationCreate';
    },
    isEditMode() {
      return this.$route.name === 'NotificationEdit';
    },
    isCloneMode() {
      return this.$route.name === 'NotificationClone';
    },
    editorInit() {
      return {
        height: 300,
        menubar: false,
        plugins:
          'advlist autolink lists link image charmap print preview anchor formatpainter searchreplace visualblocks code fullscreen insertdatetime table paste textcolor',
        toolbar:
          'undo redo | formatselect | forecolor bold italic underline | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist',
      };
    },
    currentSubject: {
      get: function () {
        if (this.showEnglishSubject) {
          return this.value.notificationSubjectEN ?? '';
        } else {
          return this.value.notificationSubject ?? '';
        }
      },
      set: function (newVal) {
        if (this.showEnglishSubject) {
          this.value.notificationSubjectEN = newVal;
        } else {
          this.value.notificationSubject = newVal;
        }
      },
    },
    currentText: {
      get: function () {
        if (this.showEnglishText) {
          return this.value.notificationTextEN ?? '';
        } else {
          return this.value.notificationText ?? '';
        }
      },
      set: function (newVal) {
        if (this.showEnglishText) {
          this.value.notificationTextEN = newVal;
        } else {
          this.value.notificationText = newVal;
        }
      },
    },
    currentShortText: {
      get: function () {
        if (this.showEnglishShortText) {
          return this.value.notificationShortTextEN ?? '';
        } else {
          return this.value.notificationShortText ?? '';
        }
      },
      set: function (newVal) {
        if (this.showEnglishShortText) {
          this.value.notificationShortTextEN = newVal;
        } else {
          this.value.notificationShortText = newVal;
        }
      },
    },
  },
  async mounted() {
  },
  methods: {
    async switchTranslationField() {
      await this.$nextTick();
      this.showEnglishSubject = !this.showEnglishSubject;
      this.showEnglishText = !this.showEnglishText;
      this.showEnglishShortText = !this.showEnglishShortText;
    },
    async onSubmit(next = false) {
      if (!(await this.$refs.form.validate())) {
          this.ShowToast(
            this.$t('error'),
            this.$t('verifyInformation'),
            'error',
          );
        return;
      }
      await this.switchTranslationField();
      if (!(await this.$refs.form.validate())) {
          this.ShowToast(
            this.$t('error'),
            this.$t('verifyInformation'),
            'error',
          );
        return;
      }
      await this.switchTranslationField();
      const payload = { ...this.value };
      await _submitNotification(payload).then(({data}) => {
          this.ShowSuccessSaveToast();
          if (this.isCreateMode || this.isCloneMode){
             this.$router.push({ name: 'NotificationEdit', params: { id: data } });
             // Persist current state of this view
             this.saveSessionData('registeredNotificationData', Object.assign({}, this.$data));
          }
          if (next) {
            this.$emit('go-to', 1);
          }
        }).catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
<style lang="scss" scoped>
  .form-group label {
    font: normal normal normal 14px/21px Lato !important;
    color: #707070 !important;
  }

  .smsLongText {
    height: 300px;
    width: 100%;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
    line-height: 1.4;
  }

  .smsShortText {
    height: 150px;
    width: 100%;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
    line-height: 1.4;
  }
</style>
