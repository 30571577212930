import { apiTurnos } from '@/core/api/';
import { serialize } from 'object-to-formdata';

const qs = require('querystring');

export const getCompanies = (organizationIds = null) => {
  const qParams = {
    params: {
      organizationIds,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  };
  return apiTurnos.get('api/company/getall', organizationIds ? qParams : null);
};

export const getCompaniesWithAppointmentsFreeSpaces = () =>
  apiTurnos.get(`/api/company/appointment/freespace/list`);

export const getCompaniesWithCoordinatedAppointments = () =>
  apiTurnos.get(`/api/company/appointment/coordinated/list`);

export const getCompaniesList = (payload) =>
  apiTurnos.get('/api/company/search', { params: { ...payload } });

export const getCompany = (id) => apiTurnos.get(`/api/company/${id}`);

export const createCompany = (model) => {
  const payload = serialize(model, {
    indices: true,
    nullsAsUndefineds: true,
  });

  return apiTurnos.post('/api/company', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateCompany = (model) => {
  const payload = serialize(model, {
    indices: true,
    nullsAsUndefineds: true,
  });

  return apiTurnos.put('/api/company', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getOtherMembers = (payload) =>
  apiTurnos.get('/api/company/othermember/search', { params: { ...payload } });

export const createMember = (payload) =>
  apiTurnos.post('/api/company/othermember', payload);

export const updateMember = (payload) =>
  apiTurnos.put('/api/company/othermember', payload);

export const deleteMember = (payload) =>
  apiTurnos.delete(`/api/company/othermember/${payload}`);

export const deleteImage = (id) =>
  apiTurnos.delete(`/api/company/${id}/image/delete`);

export const getInitNotificationProviders = (id) =>
  apiTurnos.get(`/api/company/${id}/notification/provider/init`);

export const updateNotificationProviders = (id, payload) =>
  apiTurnos.post(`/api/company/${id}/notification/provider`, payload);

export const addLocationNonWorkingDays = (id, payload) =>
  apiTurnos.post(`/api/company/${id}/location/nonworkingdays`, payload);

export const getLocationNonWorkingDays = (id, payload) =>
  apiTurnos.post(`/api/company/${id}/location/nonworkingdays/list`, payload);

export const getNonWorkingDaysOffices = (id, payload) =>
  apiTurnos.post(`/api/company/${id}/location/nonworkingdays/offices`, payload);

export const removeNonWorkingDay = (id, nonWorkingDayId) =>
  apiTurnos.delete(
    `/api/company/${id}/location/nonworkingday/${nonWorkingDayId}`,
  );

export const getAdClients = (id) => apiTurnos.get(`/api/company/${id}/sso`);

export const editAdClients = (id, payload) =>
  apiTurnos.post(`/api/company/${id}/sso`, payload);

export const getCompanyMiscellaneous = (companyId, specialField) =>
  apiTurnos.get(`/api/company/${companyId}/${specialField}/miscellaneous`);

export const getSpecialFieldConfiguration = (companyId) =>
  apiTurnos.get(`/api/company/${companyId}/specialfield/configuration`);

export const updateCompanyMiscellaneous = (payload) =>
  apiTurnos.put(
    `/api/company/${payload.companyId}/${payload.specialField}/miscellaneous`,
    payload.miscellaneous,
  );

export const getLanguages = (companyId) =>
  apiTurnos.get(`/api/company/${companyId}/languages`);
export const checkLanguageRelation = (companyId, languageId) =>
  apiTurnos.get(`/api/company/${companyId}/check/language/${languageId}`);
export const checkMiscRelation = (companyId, miscId) =>
  apiTurnos.get(`/api/company/${companyId}/check/misc/${miscId}`);

export const haveRepresentativeType = (companyId) =>
  apiTurnos.get(`/api/company/${companyId}/have/representative/type`);

export const getKioskList = (companyId) =>
  apiTurnos.get(`/api/company/${companyId}/kiosk/list`);

export const getSpecialField2 = (companyId) =>
  apiTurnos.get(`/api/company/${companyId}/specialfield2/list`);

export const getCategoryConfiguration = (companyId) =>
  apiTurnos.get(`/api/company/${companyId}/category/configuration`);

export const getCompanyConfig = (companyId) =>
  apiTurnos.get(`/api/company/${companyId}/config`);

export const loadClientInfo = (companyId, payload) => {
  return apiTurnos.post(`api/company/client/info/${companyId}/bulk`, payload);
};
