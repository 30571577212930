import { apiTurnos } from '@/core/api/';
import {
  serialize
} from 'object-to-formdata'

export const getNotifications = (payload) =>
  apiTurnos.get('/api/notification/search', { params: { ...payload } });

export const getLocations = (id, payload) =>
apiTurnos.get(`/api/notification/${id}/getLocations`, { params: { ...payload } });

export const submitNotification = (model) => {
  const payload = serialize(model, {
    indices: true,
    nullsAsUndefineds: true
  });
  return apiTurnos[model.notificationId ? 'put' : 'post']('/api/notification', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export const getNotification = (id) => apiTurnos.get(`/api/notification/${id}`);

export const getNotificationProviders = () => apiTurnos.get(`/api/notification/providers`);

export const deleteNotification = (id) =>
  apiTurnos.delete(`/api/notification/${id}`);

export const toggleActiveState = (id) =>
  apiTurnos.put(`/api/notification/${id}/toggle/active`);

export const toggleLink = (id, locationConfigurationId) =>
  apiTurnos.put(`/api/notification/${id}/office/${locationConfigurationId}/link`);

export const bulkToggleLink = (id, payload) =>
  apiTurnos.put(`/api/notification/${id}/office/link`, payload);

export const unsubscribeEmail = (payload) =>
  apiTurnos.post(`/api/notification/email/unsubscribe`, payload);

export const unsubscribeSMS = (payload) =>
  apiTurnos.post(`/api/notification/sms/unsubscribe`, payload);